import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer, Cell } from 'recharts';
import ColorScheme from '../../Styles/ColorScheme';
import { useUser } from '../../Utils/UserContext';
import { useNavigate } from 'react-router-dom';

const getLastSixMonths = () => {
    const months = ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'];
    const result = [];
    const date = new Date();
    for (let i = 0; i < 12; i++) {
        result.unshift(months[date.getMonth()]);
        date.setMonth(date.getMonth() - 1);
    }
    return result;
};

const getLastTwelveMonths = () => {
    const months = ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'];
    const result = [];
    const date = new Date();
    for (let i = 0; i < 12; i++) {
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();
        result.unshift(`${monthName}kuu ${year}`);
        date.setMonth(date.getMonth() - 1);
    }
    return result;
};

const MainView = () => {
    const { user, loading } = useUser();
    const [lineData, setLineData] = useState([]);
    const [valueData, setValueData] = useState([]);
    const [barData, setBarData] = useState([]);

    const navigate = useNavigate();

    const handleBarClick = (index, paymentStatus) => {
        const months = getLastTwelveMonths();
        const monthName = months[index];
        navigate(`/invoices`, { state: { month: monthName, paymentStatus } });
    };

    useEffect(() => {
        user.getStatistics().then(statistics => {
            const { customerCount, contractCount, contractValue, invoiceCount } = statistics;
            const { paid, unpaid } = invoiceCount;

            const finnishMonths = getLastSixMonths();

            // Reverse the fetched values
            const reversedContractCount = contractCount.reverse();
            const reversedCustomerCount = customerCount.reverse();
            const reversedContractValue = contractValue.reverse();
            const reversedPaid = paid.reverse();
            const reversedUnpaid = unpaid.reverse();

            const lineData = finnishMonths.map((month, index) => ({
                name: month,
                Sopimukset: reversedContractCount[index]
            }));

            const valueData = finnishMonths.map((month, index) => ({
                name: month, 
                Arvo: reversedContractValue[index]
            }));

            const barData = finnishMonths.map((month, index) => ({
                name: month,
                Maksettu: reversedPaid[index],
                Maksamatta: reversedUnpaid[index]
            }));

            setLineData(lineData);
            setBarData(barData);
            setValueData(valueData);
        });
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", height: "calc(100vh - 150px)" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '48%', height: 'calc(50vh - 100px)' }}>
                    <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Sopimusten keskimääräinen arvo</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={valueData} margin={{ right: 30, left: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip formatter={(value) => `${value} €`} />
                        <Legend />
                        <Bar dataKey="Arvo" fill={ColorScheme.primary} />
                    </BarChart>
                    </ResponsiveContainer>
                </Box>
                <Box sx={{ width: '48%', height: 'calc(50vh - 100px)' }}>
                    <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Aktiiviset sopimukset</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={lineData} margin={{ right: 30, left: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Sopimukset" stroke={ColorScheme.primary} />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: 'calc(50vh - 100px)' }}>
                <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Erääntyvät laskut</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={barData} margin={{ right: 30, left: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Maksettu" fill={ColorScheme.green} onClick={(data, index) => handleBarClick(index, 1)}>
                            {barData.map((entry, index) => (
                                <Cell key={`cell-${index}`} cursor="pointer" />
                            ))}
                        </Bar>
                        <Bar dataKey="Maksamatta" fill={ColorScheme.red} onClick={(data, index) => handleBarClick(index, 0)}>
                            {barData.map((entry, index) => (
                                <Cell key={`cell-${index}`} cursor="pointer" />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default MainView;